export const courseLevelToSkills: Record<string, Record<string, string[]>> = {
  L3_V1: {
    minor: [
      "Supervised",
      "Limited supervision",
      "Basic understanding",
      "Explain",
      "Proficient understanding",
      "Apply",
      "Contributing",
      "Assignment",
      "Workplace evaluation",
      "Unsupervised",
    ],
    major: [],
    capstoneV1: [
      "Industry standard",
      "Industry standards",
      "Commercially competent",
      "Competent",
    ],
    capstoneV2: [],
    capstoneV3: ["Taking ownership"],
  },
  L4_V1: {
    minor: [
      "Supervised",
      "Limited supervision",
      "Explain",
      "Apply",
      "Contributing",
    ],
    major: ["Unsupervised"],
    capstoneV1: ["Commercially competent"],
    capstoneV2: [],
    capstoneV3: ["Taking ownership"],
  },
  L4_V2: {
    minor: [
      "Limited supervision",
      "Basic understanding",
      "Proficient understanding",
      "Contributing",
    ],
    major: ["Unsupervised"],
    capstoneV1: ["Commercially competent"],
    capstoneV2: [],
    capstoneV3: ["Taking ownership"],
  },
  L4_interior_systems: {
    minor: [
      "Limited supervision",
      "Basic understanding",
      "Proficient understanding",
      "Contributing",
    ],
    major: ["Unsupervised"],
    capstoneV1: ["Commercially competent"],
    capstoneV2: [],
    capstoneV3: ["Taking ownership"],
  },
  L5_kitchen_design: {
    minor: [
      "Assignment 1",
      "Assignment 2",
      "Assignment 3",
      "Assignment 4",
      "Workplace Evolution", // TODO write code to find assignment or workplace in skilllevel change and render minor // Not required as uncaptured milestones default to minor
    ],
    major: [],
    capstoneV1: [],
    capstoneV2: ["Professional competence", "Professionally competent"],
    capstoneV3: [],
  },
  L5_tsd: {
    minor: [
      "Basic understanding",
      "Proficient understanding",
      "Not yet awarded",
      "Limited supervision",
    ],
    major: ["Unsupervised"],
    capstoneV1: ["Professionally competent"],
    capstoneV2: [],
    capstoneV3: [],
  },
  L5_supervisor: {
    minor: ["Not yet professionally competent"],
    major: [],
    capstoneV1: ["Professionally competent"],
    capstoneV2: [],
    capstoneV3: [],
  },
};

// includeData can be a title, array of items, or both

export const fieldInfo: Record<string, any> = {
  siteAddress: {
    label: "Site address",
    required: true,
    help: "Address of the site/s where the work that contributed to this milestone move took place",
  },
  projectDescription: {
    label: "Project task description",
    required: true,
    help: "Provide a brief description of the task/s completed that relate to this milestone",
    info: {
      includeData: [
        {
          title: "Volume of work:",
          items: [
            "For Set out (Size of building (m2))",
            "For Retaining walls (Lineal metres and height)",
            "For Exterior joinery (Number and type of units)",
            "For Kitchens (Size, Number of units or cost of job)",
          ],
        },
        {
          title: "Design and/or Shape of job:",
          items: [
            "Single/Multi-level",
            "Commercial/Residential",
            "New build/Renovation/Addition",
            "Site complexity (Sloping/flat/steep)",
            "Style of roof",
            "Type of cladding",
          ],
        },
        {
          title: "Task description:",
          items: [
            "What task specific information was used to make this decision? This should reflect the “DO” outcomes of the GAT which have been moved.",
            "TA summary of project used to award Unsupervised or Commercial Competence.",
          ],
        },
      ],
      example:
        "Set out example - 190m2 single level new build from ground up, L shape on a flat site. Site plans used to identify position of building, FFL and datum. Profiles constructed to height, and braced. Building lines set up and checked for square/parallel.",
      linkLabel: "Project task description (HWR)",
      link: "https://bcito.sharepoint.com/:u:/r/sites/HowWeRollV2/SitePages/Documenting-Visits-%26-Engagements-3.0.aspx?#project-description",
    },
  },
  learnerInvolvement: {
    label: "Learner involvement",
    required: true,
    help: "Document the learner's involvement in the project/s, including their role and time on the job",
    info: {
      includeData: [
        {
          items: [
            "Role (Lead, assisting, supervising others)",
            "Time on job (Start of Set out through to completion)",
          ],
        },
      ],
      example:
        "John has been involved with this whole process from the start of the set out, to being ready for footing excavations. John completed this set out in a lead role, with the assistance of a junior apprentice.",
      linkLabel: "Learner involvement (HWR)",
      link: "https://bcito.sharepoint.com/:u:/r/sites/HowWeRollV2/SitePages/Documenting-Visits-%26-Engagements-3.0.aspx?#learner-involvement",
    },
  },
  "wrap-upStatement": {
    label: "Wrap-up statement",
    required: true,
    help: "Document the final discussion had with the learner which has contributed to the final decision",
    info: {
      includeData: [
        {
          items: [
            "What information was used to make this final decision? (Does not need to cover all GAT requirements)",
            "How has the learner applied their knowledge to this specific topic and projects listed, and what was seen or heard at the final visit to showcase this application within the skillset as final assessment conclusion (Does not need to cover all GAT requirements)",
          ],
        },
      ],
      example:
        "John explained how he used dazzle to mark the ground for the rough position of the corners of the building, then allowed extra space between the building and the profiles as they needed a digger to move around. Extra bracing needed for some profiles, John had the junior apprentice holding the end of the tape while he checked measurements and ensured square/parallel. Marked final position of building line with 2 nails and a spray of dazzle.",
      linkLabel: "Wrap-up statement (HWR)",
      link: "https://bcito.sharepoint.com/:u:/r/sites/HowWeRollV2/SitePages/Documenting-Visits-%26-Engagements-3.0.aspx?#wrap-up-statement",
    },
  },
  evaluatorComments: {
    label: "Evaluator comments",
    required: true,
    help: "Feedback from the evaluator verifying the learner has achieved the competency required for the milestone movements discussed",
    info: {
      includeData: [
        {
          items: [
            "Evaluator name and confirmed level of competence",
            "Authentic evaluator feedback stating why",
          ],
        },
      ],
      example:
        "Tim verified that John completed this set out unsupervised with good accuracy. However, Tim said John could pick up on his speed to be deemed commercially competent. Tim also said that John worked well with all involved parties to complete the set-out (Surveyor/Digger Operator).",
      linkLabel: "Evaluator comments (HWR)",
      link: "https://bcito.sharepoint.com/:u:/r/sites/HowWeRollV2/SitePages/Documenting-Visits-%26-Engagements-3.0.aspx?#evaluator-comments",
    },
  },
  assessmentComments: {
    label: "Assessment comments",
    required: false,
    info: undefined,
    help: "Document any comments or feedback that relate to the milestone movement/s",
  },
  awardUsingCapability: {
    type: "checkbox",
    label: "Award using capability",
    required: false,
    info: undefined,
    help: "",
  },
};

export const fields: Record<string, any[]> = {
  minor: ["assessmentComments"],
  major: [
    "siteAddress",
    "projectDescription",
    "learnerInvolvement",
    "evaluatorComments",
  ],
  capstoneV1: [
    "awardUsingCapability",
    "siteAddress",
    "projectDescription",
    "learnerInvolvement",
    "evaluatorComments",
    "wrap-upStatement",
  ],
  capstoneV2: ["awardUsingCapability", "wrap-upStatement"], //Awarding using capability is a checkbox
  capstoneV3: ["evaluatorComments"],
};
