// Best source for dates of observed holidays in New Zealand
// https://publicholiday.co.nz/nz-public-holidays-2026.html

export default [
  { date: "2025-01-01", holidayName: "New Year's Day" },
  { date: "2025-01-02", holidayName: "Day after New Year's Day" },
  { date: "2025-02-06", holidayName: "Waitangi Day" },
  { date: "2025-04-18", holidayName: "Good Friday" },
  { date: "2025-04-21", holidayName: "Easter Monday" },
  { date: "2025-04-25", holidayName: "Anzac Day" },
  { date: "2025-06-02", holidayName: "King's Birthday" },
  { date: "2025-06-20", holidayName: "Matariki" },
  { date: "2025-10-24", holidayName: "BCITO Day" },
  { date: "2025-10-27", holidayName: "Labour Day" },
  { date: "2025-12-25", holidayName: "Christmas Day" },
  { date: "2025-12-26", holidayName: "Boxing Day" },
  { date: "2025-01-20", holidayName: "Wellington Anniversary Day" },
  { date: "2025-01-27", holidayName: "Auckland Anniversary Day" },
  { date: "2025-02-03", holidayName: "Nelson Anniversary Day" },
  { date: "2025-03-10", holidayName: "Taranaki Anniversary Day" },
  { date: "2025-03-24", holidayName: "Otago Anniversary Day" },
  { date: "2025-04-22", holidayName: "Southland Anniversary Day" },
  { date: "2025-09-22", holidayName: "South Canterbury Anniversary Day" },
  { date: "2025-10-24", holidayName: "Hawke's Bay Anniversary Day" },
  { date: "2025-11-03", holidayName: "Marlborough Anniversary Day" },
  { date: "2025-11-14", holidayName: "Canterbury Anniversary Day" },
  { date: "2025-12-01", holidayName: "Chatham Islands Anniversary Day" },
  { date: "2025-12-01", holidayName: "Westland Anniversary Day" },

  { date: "2026-01-01", holidayName: "New Year's Day" },
  { date: "2026-01-02", holidayName: "Day after New Year's Day" },
  { date: "2026-02-06", holidayName: "Waitangi Day" },
  { date: "2026-04-03", holidayName: "Good Friday" },
  { date: "2026-04-06", holidayName: "Easter Monday" },
  { date: "2026-04-27", holidayName: "Anzac Day" },
  { date: "2026-06-01", holidayName: "King's Birthday" },
  { date: "2026-07-10", holidayName: "Matariki" },
  { date: "2026-10-23", holidayName: "BCITO Day" },
  { date: "2026-10-26", holidayName: "Labour Day" },
  { date: "2026-12-25", holidayName: "Christmas Day" },
  { date: "2026-12-28", holidayName: "Boxing Day" },
  { date: "2026-01-19", holidayName: "Wellington Anniversary Day" },
  { date: "2026-01-26", holidayName: "Northland Anniversary Day" },
  { date: "2026-01-26", holidayName: "Auckland Anniversary Day" },
  { date: "2026-02-02", holidayName: "Nelson Anniversary Day" },
  { date: "2026-03-09", holidayName: "Taranaki Anniversary Day" },
  { date: "2026-03-23", holidayName: "Otago Anniversary Day" },
  { date: "2026-04-07", holidayName: "Southland Anniversary Day" },
  { date: "2026-09-28", holidayName: "South Canterbury Anniversary Day" },
  { date: "2026-10-23", holidayName: "Hawke's Bay Anniversary Day" },
  { date: "2026-11-02", holidayName: "Marlborough Anniversary Day" },
  { date: "2026-11-13", holidayName: "Canterbury Anniversary Day" },
  { date: "2026-11-30", holidayName: "Chatham Islands Anniversary Day" },
  { date: "2026-11-30", holidayName: "Westland Anniversary Day" },
];
