import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";

const minutes = 1;

export const TimeSpentTracker: React.FC = () => {
  const { online } = useContext(AppContext);
  const [isActive, setIsActive] = useState(true);

  let interval: NodeJS.Timeout;
  let timeout: NodeJS.Timeout;

  useEffect(() => {
    if (interval) clearInterval(interval);
    if (!isActive) return;

    interval = setInterval(async () => {
      const dateNow = new Date()
        .toLocaleDateString("en-GB")
        .split("/")
        .reverse()
        .join("-");
      const timeSpent = JSON.parse(
        window.localStorage.getItem("timeSpent") || "{}"
      );
      timeSpent[dateNow] = timeSpent[dateNow]
        ? timeSpent[dateNow] + minutes
        : minutes;

      if (online) {
        const resp = await fetch(`/api/time-spent`, {
          method: "POST",
          body: JSON.stringify(timeSpent),
        });

        if (resp.ok) window.localStorage.setItem("timeSpent", "{}");
      } else {
        window.localStorage.setItem("timeSpent", JSON.stringify(timeSpent));
      }
    }, 1000 * 60 * minutes);

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [online, isActive]);

  useEffect(() => {
    const handleActivity = () => {
      if (timeout) clearTimeout(timeout);
      setIsActive(true);

      timeout = setTimeout(() => {
        setIsActive(false);
      }, 1000 * 60 * (minutes + 1));
    };

    handleActivity();

    window.addEventListener("click", handleActivity);
    window.addEventListener("keydown", handleActivity);

    return () => {
      if (timeout) clearTimeout(timeout);
      window.removeEventListener("click", handleActivity);
      window.removeEventListener("keydown", handleActivity);
    };
  }, []);

  return <></>;
};
